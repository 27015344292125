@import 'src/shared/styles/pxToRem';

$container-padding-top: 9px;
$key-width: 14px;
$key-height: 12px;
$cross-width: 12px;
$cross-height: 12px;
$button-width: 30px;
$button-height: 30px;
$button-margin-right: 5px;
$scale: 1;

.container {
    padding-top: pxToRem($container-padding-top, $scale);
    width: fit-content;
    height: fit-content;
    align-items: center;

    & button {
        width: pxToRem($button-width, $scale);
        height: pxToRem($button-height, $scale);
        background: var(--blackBase);
        border: 0;
        cursor: pointer;
        margin: 0;
        padding: 0;
        align-items: center;
    }

    & button:first-child {
        margin-right: pxToRem($button-margin-right, $scale);
    }
}

.check {
    fill: #269e39;
    width: pxToRem($key-width, $scale);
    height: pxToRem($key-height, $scale);
}

.cross {
    fill: #ed1a1a;
    width: pxToRem($cross-width, $scale);
    height: pxToRem($cross-height, $scale);
}
