@import 'src/shared/styles/pxToRem';
.wrapper {
    display: flex;
    flex-direction: column;
    row-gap: pxToRem(10px);
}
.content {
    min-height: pxToRem(50px);
    display: flex;
    padding: 0 pxToRem(20px);
    align-items: center;
    border: pxToRem(2px) solid #b1b1b1;
    background-color: transparent;
    border-radius: pxToRem(10px);
    width: pxToRem(447px);
    transition: 0.1s ease border-color;
    &:has(.input:focus) {
        border-color: var(--yellow100);
    }
}
.input {
    color: var(--whiteBase);
    width: 100%;
    flex: 1;
    border: none;
    background-color: transparent;
    padding: 0 pxToRem(15px) 0 0;
    font-size: pxToRem(18px);
    &:focus {
        outline: none;
    }
    &::placeholder {
        font-weight: 400;
        font-size: inherit;
    }
}
.error {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: red;
}
