@import 'src/shared/styles/pxToRem';

$container-height: 300px;
$container-scrollbar-width: 8px;
$container-scrollbar-border-radius: 10px;
$member-margin-top: 10px;
$member-margin-left: 23px;
$member-font-size: 16px;
$member-line-height: 19px;
$member-grid-gap: 10px;
$member-fullName-margin-top: 16px;
$member-fullName-margin-right: 50px;
$member-age-margin-right: 26px;
$member-age-margin-top: 16px;
$member-role-margin-top: 16px;
$member-role-margin-right: 5px;
$scale: 1;

.container {
    height: pxToRem($container-height, $scale);
    overflow-x: hidden;
    overflow-y: scroll;
}

.member {
    margin-top: pxToRem($member-margin-top, $scale);
    margin-left: pxToRem($member-margin-left, $scale);
    font-size: pxToRem($member-font-size, $scale);
    line-height: pxToRem($member-line-height, $scale);
    grid-gap: pxToRem($member-grid-gap, $scale);
    white-space: nowrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    white-space: nowrap;
    font-weight: 400;
    color: var(--whiteBase);
}

.fullName {
    margin-top: pxToRem($member-fullName-margin-top, $scale);
    margin-right: pxToRem($member-fullName-margin-right, $scale);
    grid-column: 1;
}

.age {
    margin-top: pxToRem($member-age-margin-top, $scale);
    margin-right: pxToRem($member-age-margin-right, $scale);
    grid-column: 2;
}

.role {
    margin-top: pxToRem($member-role-margin-top, $scale);
    margin-right: pxToRem($member-role-margin-right, $scale);
    grid-column: 3;
}

.container::-webkit-scrollbar {
    width: pxToRem($container-scrollbar-width, $scale);
}

.container::-webkit-scrollbar-track {
    border-radius: pxToRem($container-scrollbar-border-radius, $scale);
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--grey200);
    border-radius: pxToRem($container-scrollbar-border-radius, $scale);
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey100);
}

.checkBox {
    padding-right: pxToRem(18px, $scale);
}
