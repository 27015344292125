@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(614px);
    height: pxToRem(350px);
    background: var(--black300);
    position: relative;

    .title {
        color: var(--whiteBase);
        font-family: Roboto;
        font-size: pxToRem(24px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: pxToRem(25px);
        left: pxToRem(24px);
    }

    .hexagonGroup {
        width: pxToRem(500px);
        position: absolute;
        top: pxToRem(68px);
        left: pxToRem(34px);
    }
    .mainGoal {
        position: absolute;
        width: pxToRem(120px);
        height: pxToRem(120px);
        top: pxToRem(218px);
        left: pxToRem(114px);
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .mainGoalIcon {
            width: pxToRem(60px);
            height: pxToRem(60px);
        }
    }
    @mixin trait() {
        width: pxToRem(70px);
        height: pxToRem(70px);
        position: absolute;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @mixin traitIcon() {
        width: pxToRem(40px);
        height: pxToRem(40px);
    }
    .additionalGoal {
        @include trait();
        top: pxToRem(120px);
        left: pxToRem(180px);
        .additionalGoalIcon {
            @include traitIcon();
        }
    }
    .trait1 {
        @include trait();
        top: pxToRem(216px);
        left: pxToRem(290px);
        .trait1Icon {
            @include traitIcon();
        }
    }
    .trait2 {
        @include trait();
        top: pxToRem(265px);
        left: pxToRem(373px);
        .trait2Icon {
            @include traitIcon();
        }
    }
    .trait3 {
        @include trait();
        top: pxToRem(216px);
        left: pxToRem(460px);
        .trait3Icon {
            @include traitIcon();
        }
    }
}
.selectableItem {
}
.isSelected {
    box-shadow: 0 0 10px 0 var(--yellow100) inset;
    border-radius: 50%;
}
