.container {
    max-height: 100vh;
    height: 100%;
    overflow-y: clip;
    overflow-x: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    position: absolute;
}
