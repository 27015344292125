@import '@/shared/styles/pxToRem.scss';

.wrapper {
    position: absolute;
    top: pxToRem(50px);
    left: pxToRem(94px);
    width: pxToRem(614px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    .title {
        color: var(--whiteBase);
        font-family: Roboto;
        font-size: pxToRem(36px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .labels {
        margin-top: pxToRem(31px);
        width: 100%;
        height: pxToRem(74px);
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .label {
            width: pxToRem(74px);
            height: pxToRem(74px);
            display: flex;
            justify-content: center;
            align-items: center;
            &.selected {
                background: rgba(0, 0, 0, 0.6);
            }
            .labelIcon {
                width: pxToRem(38px);
            }
        }
    }

    .gridBlock {
        max-height: pxToRem(485px);
        overflow: auto;
        box-sizing: border-box;
        padding: pxToRem(20px);
        padding-right: pxToRem(15px);
        background: rgba(0, 0, 0, 0.6);
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--grey200);
        }

        .grid {
            width: 100%;
            display: grid;
            gap: pxToRem(5px);
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
