@import 'src/shared/styles/pxToRem';

.select {
    width: pxToRem(250px);
}

.container {
    background: var(--black300);
    padding: pxToRem(20px);
    color: var(--whiteBase);
    display: flex;
    flex-direction: column;
    gap: pxToRem(20px);
    position: fixed;
    opacity: 0.6;
    z-index: 100;

    font-family: Roboto;
    font-size: pxToRem(18px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        opacity: 1;
    }
}

.title {
    color: var(--yellow100);
}

.pre {
    background: var(--blackBase);
    padding: pxToRem(8px);
    flex: 1;
}

.play {
    background: var(--blackBase);
    display: flex;
    align-items: center;
    justify-content: center;
    height: pxToRem(40px);
    width: pxToRem(40px);
    border-radius: pxToRem(10px);
    cursor: pointer;
}

.row {
    display: flex;
    gap: pxToRem(12px);
}

.icon {
    width: pxToRem(27px);
    height: pxToRem(27px);
}

.content {
    display: flex;
    gap: pxToRem(16px);

    & > div {
        flex: 1;
    }
}

.column {
    display: flex;
    flex-direction: column;
    gap: pxToRem(6px);
    width: 35vw;
}
