@import "src/shared/styles/pxToRem";

.container {
    border: pxToRem(1px) solid rgb(82, 82, 81);
    border-radius: pxToRem(10px);
    padding: pxToRem(20px);
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    height: pxToRem(64px);

    color: rgb(148, 148, 148);
    font-size: pxToRem(18px);
    font-weight: 500;
    line-height: pxToRem(21px);
}

.icon {
    width: pxToRem(24px);
    height: pxToRem(24px);
    fill: rgb(148, 148, 148);
    cursor: pointer;
}

.title {
    text-align: center;
}