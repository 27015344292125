@import '@/shared/styles/pxToRem';
.wrapper {
    position: relative;
    --primary-color: var(--grey500);
    --second-color: var(--yellow100);
    --third-color: var(--grey900);
    --line-height: 5px;
    height: var(--line-height);
    border-radius: 99999px;
    background-color: var(--third-color);
}
.progress_wrapper {
    border-radius: inherit;
    height: 100%;
    position: absolute;
    width: calc(100% - var(--thumb-size));
    top: 0;
    bottom: 0;
}
.progress {
    border-radius: inherit;
    height: var(--line-height);
    position: absolute;
    inset: 0;
    background-color: var(--primary-color);
}
.info {
    color: var(--whiteBase);
    position: absolute;
    top: 0;
    translate: 0 -130%;
    &.leftText {
        left: 0;
    }
    &.rightText {
        right: 0;
    }
}
.input {
    position: absolute;
    inset: 0;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    pointer-events: none;
    &::-webkit-slider-thumb {
        cursor: pointer;
        width: var(--thumb-size);
        height: var(--thumb-size);
        background-color: var(--second-color);
        border-radius: 50%;
        pointer-events: auto;
        -webkit-appearance: none;
    }
    &::-moz-range-thumb {
        cursor: pointer;
        width: var(--thumb-size);
        height: var(--thumb-size);
        background-color: var(--second-color);
        border: pxToRem(1px) solid var(--primary-color);
        border-radius: 50%;
        pointer-events: auto;
        -moz-appearance: none;
    }
}
.step {
    width: var(--thumb-size);
    height: var(--thumb-size);
    pointer-events: none;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    color: var(--blackBase);
    font-weight: 500;
    font-family: Roboto;
    background-color: var(--yellow100);
    border-radius: 50%;
    padding: pxToRem(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: pxToRem(12px);
}
.step_wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    translate: 0 -50%;
}
