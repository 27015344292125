@import 'src/shared/styles/pxToRem';

.exitCross {
    position: absolute;
    width: pxToRem(21px);
    aspect-ratio: 1/1;
    top: pxToRem(21px);
    right: pxToRem(21px);
    filter: $filter;
}

.exitCross:hover {
    cursor: pointer;
}

.header {
    position: absolute;
    width: 100%;
    top: pxToRem(45px);
    left: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    color: var(--whiteBase);
    text-align: center;
}

.mainShape {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: pxToRem(680px);
    height: pxToRem(450px);
    background: var(--blackBase);
    border: pxToRem(2px) solid $borderColor;
    border-radius: pxToRem(20px);
}

.mainShapeMysef {
    height: pxToRem(392px);
}

.mainBlock {
    position: absolute;
    top: pxToRem(135px);
    left: pxToRem(56px);
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.mainBlock > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.customerSelector {
    margin-left: pxToRem(18px);
    width: pxToRem(180px);
    height: pxToRem(30px);
    background: #272d34;
    border-radius: pxToRem(10px);
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
}

.customerSelectorBlock {
    width: pxToRem(90px);
    height: pxToRem(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: #9399a0;
}

.customerSelectorBlock:hover {
    cursor: pointer;
}

.customerSelectorBlockSelected {
    background: $backColor;
    color: $fontColor;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: #c8c7c7;
}

.selectorsBlock > div:first-child {
    margin-left: pxToRem(56px);
    width: fit-content;
}

.selectorsBlock > div:last-child {
    width: fit-content;
}

//---------------select block

.selectWrapper {
    position: relative;
    z-index: 10;
    margin: 0 0 0 pxToRem(29px);
}

.select {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: pxToRem(220px);
    height: pxToRem(38px);
    border: pxToRem(1px) solid var(--whiteBase);
    border-radius: pxToRem(10px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
    appearance: none;
    padding-left: pxToRem(15px);
    padding-right: pxToRem(14px);
}

.select:hover {
    cursor: pointer;
}

.select::after {
    content: '';
    display: block;
    position: absolute;
    display: block;
    width: pxToRem(12px);
    height: pxToRem(38px);
    top: pxToRem(0px);
    right: pxToRem(14px);
    background: url(/shared/assets/images/vectors/Store/shared/selectArrowPic.svg)
        center no-repeat;
}

.openSelect {
    position: absolute;
    top: pxToRem(41px);
    left: 0;
    width: pxToRem(220px);
    height: fit-content;
    overflow: hidden;
    box-sizing: border-box;
    background: var(--blackBase);
    border: pxToRem(1px) solid $borderColor;
    border-radius: pxToRem(10px);
}

.selectOption {
    width: pxToRem(220px);
    height: pxToRem(40px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
    box-sizing: border-box;
    padding-left: pxToRem(15px);
    display: flex;
    align-items: center;
}

.selectOption:hover {
    cursor: pointer;
}

.selectOptionSelected {
    background: $backColor;
    color: $fontColor;
}

//-------------radio-----------

.radio {
    display: block;
    opacity: 0;
    width: pxToRem(13px);
    height: pxToRem(13px);
    margin: 0;
}

.label {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: fit-content;
    height: pxToRem(23px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: var(--whiteBase);
    margin: pxToRem(10px) 0 0 pxToRem(40px);
}

.label:hover {
    cursor: pointer;
}

.radio:checked + .label:before,
.radio:not(:checked) + .label:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: pxToRem(-35px);
    top: pxToRem(0px);
    width: pxToRem(18px);
    height: pxToRem(18px);
    border: pxToRem(1px) solid var(--whiteBase);
    border-radius: 50%;
}

.radio:checked + .label:after,
.radio:not(:checked) + .label:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: pxToRem(-33px);
    top: pxToRem(1.5px);
    width: pxToRem(15px);
    height: pxToRem(15px);
    border-radius: 100%;
    background-color: $backColor;
}

.radio:not(:checked) + .label:after {
    opacity: 0;
}

.radio:checked + .label:after {
    opacity: 1;
}

//-------

.forPayment {
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: pxToRem(35px);
    background: #212121;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: var(--whiteBase);
    padding: 0 pxToRem(17px) 0 pxToRem(20px);
    margin: 0 0 0 pxToRem(16px);
}

.mainBlock > div:first-child {
    margin: 0 0 pxToRem(20px) 0;
}

.mainBlock > div:nth-child(2) {
    margin: 0 0 pxToRem(20px) 0;
}

.mainBlock > div:nth-child(3) {
    margin: 0 0 pxToRem(35px) 0;
}

.paymmentButton {
    position: absolute;
    bottom: pxToRem(45px);
    left: pxToRem(250px);
    width: pxToRem(168px);
    height: pxToRem(39px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: $backColor;
    border-radius: pxToRem(10px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: $fontColor;
}

.paymmentButton:hover {
    cursor: pointer;
}

.cardBalance {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    letter-spacing: 0.05em;
    color: #c8c7c7;
    margin: pxToRem(10px) pxToRem(3px) 0 pxToRem(30px);
}

.cardBalanceValue {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    letter-spacing: 0.05em;
    color: var(--whiteBase);
    margin: pxToRem(10px) 0 0 0;
}
