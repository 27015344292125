@import '@/shared/styles/pxToRem.scss';
.item {
    position: absolute;
}

.container {
    position: relative;
    pointer-events: none;
}

.items {
    position: absolute;
    top: 0;
}
.item {
    pointer-events: auto;
}
.image {
    width: pxToRem(893px);
    height: pxToRem(893px);
}
