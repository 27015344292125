@import 'src/shared/styles/pxToRem';

$scale: 1;

.container {
    user-select: none;
    height: pxToRem(50px, $scale);
    color: #949494;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(18px, $scale);
    line-height: pxToRem(19px, $scale);
}

.select {
    cursor: pointer;
    line-height: 1;
    outline: 0;
    padding-left: pxToRem(10px, $scale);
    height: inherit;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    justify-content: space-between;
}

.hasValue {
    color: var(--whiteBase);
}

.rounded {
    .select,
    .wrapper,
    .options {
        border-radius: pxToRem(10px, $scale);
    }
}

.wrapper {
    box-sizing: border-box;
    background: #222223;
    overflow: hidden;
    padding-right: pxToRem(4px, $scale);
    margin-top: pxToRem(4px, $scale);
    position: relative;
}

.options {
    height: 0;
    transition: 0.5s all ease;
    overflow: scroll;
    box-sizing: border-box;
    font-size: pxToRem(18px, $scale);
    color: var(--whiteBase);
    .option {
        padding-left: pxToRem(10px, $scale);
        background-color: #222223;
        cursor: pointer;
        border-bottom: pxToRem(1px, $scale) solid var(--black400);
        height: pxToRem(34px, $scale);
        display: flex;
        align-items: center;
        transition: 0.3s all;

        &:hover {
            color: #949494;
        }
    }

    &::-webkit-scrollbar-thumb:vertical {
        background: var(--grey400);
        height: pxToRem(3px, $scale);
        border-radius: pxToRem(3px, $scale);
        width: 0;
    }

    &::-webkit-scrollbar {
        height: 0px;
        width: pxToRem(6px, $scale);
        background: rgba(1, 1, 1, 0);
    }

    &::-webkit-scrollbar-track {
        margin: pxToRem(8px, $scale) 0;
    }
}

.arrow {
    transition: 0.5s transform ease;
    margin-right: pxToRem(10px, $scale);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    margin-left: pxToRem(18px, $scale);
}

.clicked {
    .arrow {
        transform: rotate(270deg);
    }
    .options {
        height: pxToRem(calc(27px * 6), $scale);
    }
}

.activeOption {
    background: #1c1c1c;
}
