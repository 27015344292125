@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(255px);
    height: pxToRem(157px);
    position: relative;
    margin-top: pxToRem(-80px);
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .chipImage {
        position: absolute;
        left: pxToRem(5px);
        top: pxToRem(15px);
        width: 50%;
    }
    .number {
        position: absolute;
        top: pxToRem(90px);
        left: pxToRem(19px);
        text-shadow: pxToRem(0px) pxToRem(1.5px) pxToRem(1.1px)
            rgba(0, 0, 0, 0.29);
        font-family: 'OCRAStd';
        font-size: pxToRem(14px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(
            20deg,
            var(--whiteBase) 32.93%,
            #dedede 58.98%,
            var(--whiteBase) 87.77%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .date {
        position: absolute;
        top: pxToRem(115px);
        left: pxToRem(19px);
        text-shadow: 0px 1.5px 1.1px rgba(0, 0, 0, 0.29);
        font-family: 'OCRAStd';
        font-size: pxToRem(9px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(
            20deg,
            var(--whiteBase) 32.93%,
            #dedede 58.98%,
            var(--whiteBase) 87.77%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .name {
        position: absolute;
        top: pxToRem(126px);
        left: pxToRem(19px);
        text-shadow: 0px 1.5px 1.1px rgba(0, 0, 0, 0.29);
        font-family: 'AvertaCY';
        font-size: pxToRem(9px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(
            20deg,
            var(--whiteBase) 32.93%,
            #dedede 58.98%,
            var(--whiteBase) 87.77%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
