@import '@/shared/styles/pxToRem.scss';

@mixin button() {
    width: pxToRem(70px);
    height: pxToRem(36px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--whiteBase);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Brutal Type';
    font-size: pxToRem(16px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: pxToRem(3px);
}

.wrapper {
    width: pxToRem(480px);
    height: pxToRem(80px);
    position: absolute;
    bottom: pxToRem(115px);
    left: pxToRem(742px);
    display: grid;
    grid-template-areas:
        'a b'
        'a c';
    row-gap: pxToRem(8px);
    column-gap: pxToRem(10px);
    display: none;
    &Visible {
        display: grid;
    }
    .info {
        grid-area: a;
        width: pxToRem(400px);
        height: 100%;
        border-radius: pxToRem(3px);
        background: rgba(17, 17, 17, 0.5);
        backdrop-filter: blur(pxToRem(2.5px));
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 pxToRem(20px);
        gap: pxToRem(15px);
        .bell {
            width: pxToRem(21px);
            height: pxToRem(26px);
        }
        .suggestion {
            color: var(--whiteBase);
            font-variant-numeric: lining-nums proportional-nums;
            font-family: 'Brutal Type';
            font-size: pxToRem(15px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            & span {
                font-weight: 900;
            }
        }
    }
    .yes {
        @include button();
        grid-area: b;
        background: rgba(121, 228, 71, 0.9);
    }
    .no {
        @include button();
        grid-area: c;
        background: var(--red400);
    }
}
