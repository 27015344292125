@import "src/shared/styles/pxToRem";

.container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.hudInfo {
    position: absolute;
    left: pxToRem(345px);
    bottom: pxToRem(43px);
}

.weaponSlots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: pxToRem(18px)
}

.needs {
    position: absolute;
    right: pxToRem(144px);
    bottom: pxToRem(33px);
}

.wishes{
    position:absolute;
    right:pxToRem(29px);
    bottom:pxToRem(18px);
}

.hints{
    position:absolute;
    left:pxToRem(20px);
    top:pxToRem(453px);
}

.chat{
    position:absolute;
    left:pxToRem(4px);
    top:pxToRem(10px);
}

.header {
    position: absolute;
    right: pxToRem(29px);
    top: pxToRem(31px)
}

.actionList {
    position: absolute;
    left: pxToRem(20px);
    top: 50%;
    transform: translateY(-50%);
}

.autoSensors {
    position: absolute;
    right: pxToRem(25px);
    bottom: pxToRem(20px);
}

.interactions {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.tickets {
    position:absolute;
    right:pxToRem(261px);
    top:pxToRem(20px);
}