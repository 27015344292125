@import "src/shared/styles/pxToRem";

.container {
    width: pxToRem(600px);
    
    color: rgb(255, 255, 255);
}

.item {
    width: pxToRem(519px);
    padding: pxToRem(40px);
    box-sizing: border-box;
    font-size: pxToRem(18px);
    font-weight: 400;
    line-height: pxToRem(26px);
    font-family: Roboto;
}

.dots {
    display: flex;
    gap: pxToRem(10px);
    justify-content: center;
    margin-top: pxToRem(24px);
}

.dot {
    background: rgb(255, 255, 255);
    opacity: 0.3;
    width: pxToRem(82px);
    height: pxToRem(3px);
}

.activeDot {
    background: rgb(255, 216, 14);
    opacity: 1;
}

.titleBlock {
    text-align: center;
    font-size: pxToRem(24px);
    font-weight: 500;
    line-height: pxToRem(28px);
    letter-spacing: 0%;
    color: rgb(255, 255, 255);
    margin-right: pxToRem(40px); 
    margin-left: pxToRem(40px);

    padding-bottom: pxToRem(18px);
    border-bottom: pxToRem(1px) solid rgb(109, 109, 109);
    margin-bottom: pxToRem(16px);
}
