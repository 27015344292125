@import 'src/shared/styles/pxToRem';

.container {
    border-radius: pxToRem(10px);
    background: var(--blackBase);
    opacity: 0.5;
    padding: pxToRem(16px);
    cursor: text;
    display: flex;
    align-items: center;

    & input,
    & input:focus,
    & input:active,
    & input:focus-visible {
        background: none;
        border: none;
        outline: none;

        color: var(--whiteBase);
        font-size: pxToRem(18px);
        font-weight: 400;
        line-height: pxToRem(21px);
    }

    & input {
        flex: 1;
    }

    & input::placeholder {
        color: rgb(148, 148, 148);
    }
}

.icon {
    width: pxToRem(14px);
    height: pxToRem(14px);
    margin-right: pxToRem(8px);
}

.error {
    color: red;
}
