@import '@/shared/styles/pxToRem';

.container {
    border-radius: pxToRem(10px);
    background: rgb(255, 216, 14);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: pxToRem(16px);
    border: none;

    color: var(--blackBase);
    font-size: pxToRem(18px);
    font-weight: 500;
    line-height: pxToRem(21px);
    cursor: pointer;
    transition: 0.2s ease;
    transition-property: background-color color opacity;
    &:disabled {
        background-color: var(--whiteBase) 33;
        color: var(--whiteBase);
    }
    &:hover:not(:disabled) {
        opacity: 0.6;
    }
}

.full {
    width: 100%;
}

.secondary {
    background: rgb(29, 29, 29);
    color: rgb(148, 148, 148);
}
