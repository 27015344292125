@import '@/shared/styles/pxToRem.scss';
.container {
    width: pxToRem(95px);
    height: pxToRem(95px);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: rgba(0, 0, 0, 0.4);
}

.icon {
    width: pxToRem(45px);
    height: pxToRem(45px);
    fill: var(--whiteBase);
}

.active {
    background: var(--yellow100);

    & > .icon {
        fill: var(--blackBase);
    }
}
