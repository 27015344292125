@import '@/shared/styles/pxToRem.scss';

@font-face {
    font-family: 'Seg-7';
    src: url('assets/fonts/Segment7Standard.otf');
}

@font-face {
    font-family: 'Heebo';
    src: url('assets/fonts/Heebo-Bold.ttf');
}

@font-face {
    font-family: 'Heebo-Regular';
    src: url('assets/fonts/HeeboRegular.ttf');
}

@font-face {
    font-family: 'Plate-Font';
    src: url('assets/fonts/plateFont.ttf');
}

@font-face {
    font-family: 'Plate-Font-Hilite';
    src: url('assets/fonts/plateFontHilite.ttf');
}

@font-face {
    font-family: 'Plate-Font-Lolite';
    src: url('assets/fonts/plate-font-lolite.ttf');
}

@font-face {
    font-family: 'Plate-Font-Shadow';
    src: url('assets/fonts/plateFontShadow.ttf');
}

.wrapper {
    font-family: 'Heebo', Verdana, Geneva, Tahoma, sans-serif;
    font-size: pxToRem(13px);
    box-sizing: border-box;
    user-select: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* Removes the outline when buttons have been clicked */
button:focus {
    outline: none;
}

.radarFrame {
    width: pxToRem(715px);
    height: pxToRem(230px);

    position: absolute;
    margin: auto;

    top: calc(100% - pxToRem(230px));
    left: calc(100% - pxToRem(715px));

    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/frame.png);
    background-size: contain;

    /* Settings for scaling */
    transform: scale(1);
    transform-origin: 0 0;

    transition: transform 0.5s;

    z-index: 12;
}

.radarFrame .frame_border {
    width: pxToRem(685px);
    height: pxToRem(210px);

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgb(20, 22, 18);

    clip-path: polygon(
        pxToRem(20px) pxToRem(2px),
        pxToRem(665px) pxToRem(2px),
        pxToRem(682px) 30%,
        pxToRem(682px) 70%,
        pxToRem(665px) pxToRem(208px),
        pxToRem(20px) pxToRem(208px),
        pxToRem(3px) 70%,
        pxToRem(3px) 30%
    );
}

.radar_container {
    width: pxToRem(675px);
    height: pxToRem(200px);

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: grid;
    grid-template-columns: 1fr pxToRem(635px) 1fr;
}

.panel_side {
    width: 100%;
    height: 100%;
    z-index: 15;
    box-shadow: inset pxToRem(0px) pxToRem(20px) pxToRem(20px) - pxToRem(15px)
        rgba(0, 0, 0, 0.4);
}
.panel_left {
    clip-path: polygon(0 30%, 80% 0, 100% 0, 100% 100%, 80% 100%, 0 70%);
    transform: translateX(pxToRem(1px));
    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/bg_left.webp);
    background-repeat: no-repeat;
}

.panel_right {
    clip-path: polygon(0 0, 20% 0, 100% 30%, 100% 70%, 20% 100%, 0 100%);
    transform: translateX(pxToRem(-1px));
    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/bg_right.webp);
    background-repeat: no-repeat;
}

.plate_blue {
    color: rgb(0, 0, 163);
}

.plate_yellow {
    color: rgb(255, 255, 0);
}

.green {
    color: rgb(0, 255, 0);
}

.red {
    color: rgb(255, 0, 0);
}

.arrow {
    width: pxToRem(11px);
    height: pxToRem(15.4px);
    min-width: pxToRem(11px);
    min-height: pxToRem(15.4px);
    margin: pxToRem(8px) auto;
    background-color: var(--blackBase);
    clip-path: polygon(
        50% 0,
        100% 55%,
        65% 55%,
        65% 100%,
        35% 100%,
        35% 55%,
        0 55%
    );
}
.arrow_down {
    transform: rotate(180deg);
}

.active {
    color: rgb(242, 70, 24);
    text-shadow: 0 0 pxToRem(6px) rgba(235, 62, 15, 0.5);
}
.active_arrow {
    background-color: rgb(242, 70, 24);
}

.radar {
    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/bg.webp);
    background-repeat: no-repeat;

    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns:
        pxToRem(45px) pxToRem(50px) pxToRem(150px) pxToRem(35px)
        pxToRem(50px) pxToRem(135px) pxToRem(35px) pxToRem(135px);
    gap: 0 0;

    box-shadow: inset pxToRem(0px) pxToRem(20px) pxToRem(20px) - pxToRem(15px)
        rgba(0, 0, 0, 0.4);
}
.radar .label {
    font-size: pxToRem(15px);
    text-align: center;
    width: 100%;
    position: absolute;
    color: rgb(255, 255, 255);
}
.radar .label_top {
    top: 0;
    left: 0;
    padding-top: pxToRem(3px);
}

.radar .label_bottom {
    bottom: 0;
    left: 0;
    padding-bottom: pxToRem(3px);
}

.radar .pwr_button_container {
    display: flex;
    flex-direction: column;
}
.radar .pwr_button_container .pwr_button {
    width: pxToRem(45px);
    height: pxToRem(45px);
    margin: auto;
    background: linear-gradient(
        to bottom,
        rgba(230, 230, 230, 0.8),
        rgb(40, 168, 40) 10%,
        rgb(0, 134, 0)
    );
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(3px) pxToRem(0px)
        rgb(80, 80, 80);
    text-align: center;
    font-size: pxToRem(14px);
    color: rgb(34, 34, 34);
    line-height: pxToRem(45px);
    border-radius: pxToRem(10px);
}
.radar .pwr_button_container .pwr_button:hover {
    background: linear-gradient(
        to bottom,
        rgba(240, 240, 240, 0.8),
        rgb(50, 178, 50) 10%,
        rgb(0, 144, 0)
    );
}

.radar .pwr_button_container .pwr_button:active {
    background: linear-gradient(
        to bottom,
        rgba(220, 220, 220, 0.8),
        rgb(30, 158, 30) 10%,
        rgb(0, 124, 0)
    );
    box-shadow: inset pxToRem(0px) pxToRem(0px) pxToRem(3px) pxToRem(0px)
        rgb(80, 80, 80);
}

.radar .modes_container {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.radar .modes_container .modes {
    display: flex;
    flex-direction: column;

    padding: pxToRem(10px) 0;
}
.radar .modes_container .modes p {
    margin: auto;
}

.radar .modes_container .fast_top {
    margin-top: pxToRem(15px);
    margin-bottom: pxToRem(10px);
    padding-top: pxToRem(10px);
    padding-bottom: 0;
}

.radar .modes_container .fast_bottom {
    margin-top: pxToRem(10px);
    margin-bottom: pxToRem(15px);
    padding-top: 0;
    padding-bottom: pxToRem(10px);
}

.radar .speeds_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.radar .speeds_container .display {
    display: grid;
    grid-template-columns: 1fr;

    height: pxToRem(70px);

    border: pxToRem(2px) solid var(--blackBase);

    margin: auto 0;

    /* If you would like your strong target window to be solid rather than have a gradient, swap the comment for the 2 lines below */
    background-color: rgb(61, 18, 0);
    /* background: linear-gradient( to bottom, rgb( 52, 13, 1 ), rgb( 57, 16, 0 ) 40%, rgb( 65, 25, 4 ) 85% ); */
}
.radar .speeds_container .display p {
    grid-row-start: 1;
    grid-column-start: 1;

    font-size: pxToRem(70px);
    text-align: center;

    line-height: 115%;

    margin: 0 auto;
    letter-spacing: pxToRem(10px);

    margin-right: pxToRem(-9px);
}

.radar .speeds_container .fast {
    height: pxToRem(60px);

    /* If you would like your fast target window to be solid rather than have a gradient, swap the comment for the 2 lines below */
    background-color: rgb(50, 0, 0);
    /* background: linear-gradient( to bottom, rgb( 40, 0, 0 ), rgb( 45, 0, 0 ) 40%, rgb( 50, 0, 0 ) 85% ); */
}
.radar .speeds_container .fast p {
    font-size: pxToRem(60px);
    line-height: 110%;
    margin-right: pxToRem(-8px);
}

.radar .speeds_container .fast_top {
    margin-top: pxToRem(27px);
}

.radar .speeds_container .fast_bottom {
    margin-bottom: pxToRem(27px);
}

.radar .speed_arrows_container {
    display: flex;
    flex-direction: column;
}
.radar .speed_arrows_container .speed_arrows {
    height: pxToRem(70px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: auto 0;
}

.radar .speed_arrows_container .fast_top {
    margin-top: pxToRem(22px);
}

.radar .speed_arrows_container .fast_bottom {
    margin-bottom: pxToRem(22px);
}

.radar .patrol_and_logo_container {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    height: 100%;
}
.radar .patrol_and_logo_container .logo {
    width: 75%;
    font-size: pxToRem(18px);
    text-align: center;
    line-height: 100%;
    margin-top: pxToRem(15px);
    font-style: italic;
    color: rgb(255, 255, 255);
}
.radar .patrol_and_logo_container .logo .name {
    font-size: pxToRem(24px);
}

.radar .patrol_and_logo_container .display {
    /* If you would like your patrol speed window to be solid rather than have a gradient, swap the comment for the 2 lines below */
    background-color: rgb(0, 57, 35);
    /* background: linear-gradient( to bottom, rgb( 0, 40, 29 ), rgb( 0, 46, 32 ) 40%, rgb( 1, 64, 27 ) 85% ); */
}

.radar .patrol_and_logo_container .speed_label {
    color: rgb(255, 255, 255);
    margin-bottom: pxToRem(40px);
}

/* Colours and fonts used in multiple areas that are similar */
.radar .display p {
    font-family: 'Seg-7';
}

.radar .display .ghost_speed {
    color: rgb(90, 35, 1);
}

.radar .display .speed {
    color: rgb(252, 113, 1);
    text-shadow: 0 0 pxToRem(8px) rgba(252, 114, 1, 0.65);
}

.radar .display .fast_ghost_speed {
    color: rgb(70, 0, 0);
}

.radar .display .fast_speed {
    color: rgb(243, 12, 10);
    text-shadow: 0 0 pxToRem(8px) rgba(243, 14, 10, 0.65);
}

.radar .display .patrol_ghost_speed {
    color: rgb(0, 91, 68);
}

.radar .display .patrol_speed {
    color: rgb(15, 244, 57);
    text-shadow: 0 0 pxToRem(8px) rgba(15, 244, 57, 0.65);
}

.plate_hit {
    animation: plate_flash linear 0.6s infinite;
}

@keyframes plate_flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.keyLockLabel {
    position: absolute;
    left: 0;
    right: 0;
    bottom: pxToRem(300px);

    text-align: center;
    font-size: pxToRem(30px);

    color: rgb(255, 255, 255);
    text-shadow: pxToRem(3px) pxToRem(2px) pxToRem(5px) var(--blackBase);

    z-index: 15;
}
.keyLockLabel span {
    font-size: pxToRem(30px);
}

.helpWindow {
    width: 75%;
    height: 90%;

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: grid;
    grid-template-rows: 90% 10%;
    justify-items: center;

    z-index: 16;
}
.helpWindow iframe {
    width: 100%;
    height: 100%;

    display: block;
}

.helpWindow .close {
    width: pxToRem(150px);
    height: pxToRem(50px);

    margin: auto;

    font-size: pxToRem(18px);

    border-radius: pxToRem(10px);
    border: none;
    background-color: rgb(225, 225, 225);
}
.helpWindow .close:hover {
    background-color: rgb(255, 255, 255);
}

.helpWindow .close:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}

@media (max-width: pxToRem(1024px)) {
    .helpWindow {
        width: 80%;
    }
}

.quickStart {
    width: 50%;
    height: 62.5%;

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: grid;
    grid-template-rows: 80% 20%;

    z-index: 18;
}
.quickStart iframe {
    width: 100%;
    height: 100%;
}

.quickStart .close {
    width: pxToRem(150px);
    height: pxToRem(50px);

    margin: auto;

    font-size: pxToRem(18px);

    border-radius: pxToRem(10px);
    border: none;
    background-color: rgb(225, 225, 225);
}
#quickStart .close:hover {
    background-color: rgb(255, 255, 255);
}

#quickStart .close:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}

@media (max-width: pxToRem(1280px)) {
    .quickStart {
        width: 80%;
    }
}

.newUser {
    width: pxToRem(400px);
    height: pxToRem(150px);

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(to bottom, rgb(70, 70, 70), rgb(45, 45, 45));
    border: pxToRem(3px) solid var(--blackBase);
    color: rgb(255, 255, 255);

    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    grid-template-rows: 65% 35%;
    justify-items: center;
    align-items: center;

    z-index: 17;
}
.newUser .msg {
    grid-column: 1 / 5;
    font-size: pxToRem(18px);
    text-align: center;
    margin: pxToRem(10px);
}

.newUser button {
    width: pxToRem(100px);
    height: pxToRem(30px);
    font-size: pxToRem(18px);
    border-radius: pxToRem(10px);
    border: none;
    background-color: rgb(225, 225, 225);
}
.newUser button:hover {
    background-color: rgb(255, 255, 255);
}

.newUser button:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}

.newUser .btn_yes {
    grid-column: 2;
}

.newUser .btn_no {
    grid-column: 3;
}

.xMitOn {
    color: aqua;
}
