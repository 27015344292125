@import '@/shared/styles/pxToRem';

.wrapper {
}
.color_item {
    background-color: var(--color);
    width: 100%;
    height: 100%;
    transition: 0.2s ease;
}
.color_active {
    opacity: 0.6;
}
.variants_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid var(--whiteBase);
    font-size: pxToRem(40px);
    color: var(--whiteBase);
    transition: 0.2s ease;
}
.variant_active {
    opacity: 0.7;
}
