@import '@/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.bottomBackground {
    position: absolute;
    bottom: pxToRem(15px);
    left: pxToRem(12px);
    width: pxToRem(638px);
    height: pxToRem(240px);
    background: #454545;
    box-shadow: inset pxToRem(0px) pxToRem(0px) pxToRem(34px) pxToRem(3px)
        rgba(255, 255, 255, 0.25);
}

.navigation {
    position: absolute;
    top: pxToRem(146px);
    left: pxToRem(47px);
    display: grid;
    grid-template-areas:
        'leftTopNavBlock rightTopNavBlock'
        'leftBottomNavBlock rightBottomNavBlock';
    column-gap: pxToRem(31px);
    row-gap: pxToRem(15px);
}

.leftTopNavBlock {
    grid-area: leftTopNavBlock;
}
.rightTopNavBlock {
    grid-area: rightTopNavBlock;
}
.leftBottomNavBlock {
    grid-area: leftBottomNavBlock;
}
.rightBottomNavBlock {
    grid-area: rightBottomNavBlock;
}

.navBlock {
    width: pxToRem(268px);
    height: pxToRem(121px);
    background: var(--whiteBase);
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.29);
    position: relative;
    overflow: hidden;
}

.navTitle {
    position: absolute;
    top: pxToRem(16px);
    left: pxToRem(18px);
    width: fit-content;
    height: fit-content;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: var(--blackBase);
    z-index: 2;
}

.backgroundBlock {
    position: absolute;
    top: pxToRem(-28px);
    left: pxToRem(93px);
    width: pxToRem(70.75px);
    height: pxToRem(188.17px);
    background: linear-gradient(
        to top,
        rgba(196, 196, 196, 0.3),
        var(--whiteBase)
    );
    transform: rotate(208.18deg);
}

.navPic {
    position: absolute;
    bottom: pxToRem(17px);
    left: pxToRem(203px);
    width: fit-content;
    height: fit-content;
}
