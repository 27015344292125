@import "src/shared/styles/pxToRem";

.sidebar {
    grid-area: sidebar;
    overflow-y:auto;
    scrollbar-width: none;
}

.sidebarButton {
    width: pxToRem(100px);
    height: pxToRem(100px);
    background: rgba(0, 0, 0, 0.6);
    margin-bottom: pxToRem(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebarButton:hover {
    cursor: pointer;
}

.sidebarButtonSelected {
    background: $backColor;
}
.sidebarButtonPicDefault{
    width:pxToRem(66px);
    height:pxToRem(58px);
}
.sidebarButtonPic {
    filter: $filter;
}