@import '@/shared/styles/pxToRem.scss';

@mixin font() {
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
}

.wrapper {
    width: pxToRem(1920px);
    height: pxToRem(1080px);
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(9.75px);
    position: relative;
    overflow: hidden;
    .paymentInfoBlock {
        position: absolute;
        top: pxToRem(16px);
        left: pxToRem(434px);
        width: pxToRem(1022px);
        height: pxToRem(210px);
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: pxToRem(30px);
        border-radius: pxToRem(10px);
        border: pxToRem(1px) solid var(--grey700);
        background: rgba(31, 31, 31, 0.8);
        backdrop-filter: blur(19.15px);
        .paymentInfoBlockTitle {
            @include font();
            color: var(--whiteBase);
            text-align: center;
            font-size: pxToRem(28px);
            font-weight: 600;
        }
        .paymentValueBlock {
            display: flex;
            width: pxToRem(640px);
            height: pxToRem(73px);
            padding: 0 pxToRem(41px);
            justify-content: space-between;
            align-items: center;
            border-radius: pxToRem(5px);
            background: var(--black500-80);
            .paymentValueTitle {
                @include font();
                color: var(--grey400);
                text-align: center;
                font-size: pxToRem(22px);
                font-weight: 400;
            }
            .paymentValue {
                @include font();
                color: var(--green400);
                text-align: center;
                font-size: pxToRem(22px);
                font-weight: 600;
            }
        }
        .rightTooltip {
            position: absolute;
            top: pxToRem(65px);
            right: -30%;
            box-sizing: border-box;
            padding: pxToRem(25px) 0 0 pxToRem(36px);
            width: pxToRem(293px);
            height: pxToRem(109px);
            background: url(/shared/assets/images/backgrounds/PaymentSystem/_/rightTooltipBackground.svg)
                center / contain no-repeat;
            .rightTooltipTitle {
                @include font();
                color: var(--whiteBase);
                font-size: pxToRem(16px);
                font-weight: 600;
            }
            .rightTooltipText {
                @include font();
                margin: pxToRem(9px) 0 0 0;
                color: var(--grey500);
                font-size: pxToRem(12px);
                font-weight: 400;
            }
        }
    }
    .terminal {
        position: absolute;
        top: pxToRem(247px);
        left: pxToRem(436px);
        width: pxToRem(1020px);
        height: pxToRem(516px);
        border-radius: pxToRem(16px);
        background: url(/shared/assets/images/backgrounds/PaymentSystem/_/terminalBackground.webp)
            lightgray center / 102% 102% no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .leftTooltip {
            position: absolute;
            top: pxToRem(320px);
            left: pxToRem(-392px);
            box-sizing: border-box;
            padding: pxToRem(21px) 0 0 pxToRem(23px);
            width: pxToRem(293px);
            height: pxToRem(116px);
            background: url(/shared/assets/images/backgrounds/PaymentSystem/_/leftTooltipBackground.svg)
                center / contain no-repeat;
            .leftTooltipTitle {
                @include font();
                color: var(--whiteBase);
                font-size: pxToRem(16px);
                font-weight: 600;
            }
            .leftTooltipText {
                @include font();
                margin: pxToRem(9px) 0 0 0;
                color: var(--grey500);
                font-size: pxToRem(12px);
                font-weight: 400;
            }
        }
        .cashGetter {
            margin: 0 0 0 pxToRem(252px);
            display: flex;
            width: pxToRem(365px);
            height: pxToRem(365px);
            justify-content: center;
            align-items: center;
            border-radius: pxToRem(14px);
            border: pxToRem(4px) solid var(--black1200);
            background: rgba(0, 0, 0, 0.2);
            .cashGetterLogo {
                width: pxToRem(80px);
            }
        }
        .pos {
            position: relative;
            margin: 0 0 0 pxToRem(95px);
            width: pxToRem(248px);
            height: pxToRem(463px);
            border-top-left-radius: pxToRem(30px);
            border-top-right-radius: pxToRem(30px);
            border-bottom-right-radius: pxToRem(8px);
            border-bottom-left-radius: pxToRem(8px);
            background: url(/shared/assets/images/backgrounds/PaymentSystem/_/posBackground.webp)
                lightgray 50% 25% / 115% 108% no-repeat;
            box-shadow: 0 pxToRem(9px) pxToRem(16.1px) pxToRem(-1px)
                rgba(0, 0, 0, 0.59);
            .nfc {
                position: absolute;
                top: pxToRem(32px);
                left: 50%;
                translate: -50% 0;
                width: pxToRem(34px);
            }
            .posScreen {
                position: absolute;
                top: pxToRem(154px);
                left: pxToRem(17px);
            }
        }
    }
    .cardsPlace {
        position: absolute;
        top: pxToRem(208px);
        right: pxToRem(-93px);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column-reverse;
        .cardWrapper {
            position: relative;
        }
    }
    .wallet {
        position: absolute;
        top: pxToRem(833px);
        left: pxToRem(537px);
        width: pxToRem(466px);
        height: pxToRem(291px);
        .walletImage {
            width: pxToRem(466px);
        }
    }
    .cashImage {
        position: absolute;
        top: pxToRem(833px);
        left: pxToRem(537px);
        background: url(/shared/assets/images/illustrations/PaymentSystem/_/cashImage.webp)
            center/contain no-repeat;
        width: pxToRem(439px);
        height: pxToRem(257px);
        opacity: 0;
    }
    .phone {
        width: pxToRem(252px);
        height: pxToRem(510px);
        background-color: var(--red200);
        position: absolute;
        top: pxToRem(833px);
        left: pxToRem(1063px);
    }
}
