@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: 108%;
    height: 108%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: space-between;

    .tile {
        width: pxToRem(78px);
        height: pxToRem(78px);
        border-radius: 10px;
        background: var(--black1100);
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: pxToRem(1px) solid transparent;
        transition: 0.2s;
        & img {
            height: pxToRem(44px);
        }
        &:hover {
            border: pxToRem(1px) solid var(--yellow100);
            transition: 0.2s;
        }
    }
}
