@import '@/shared/styles/pxToRem.scss';

.wrapper {
    position: absolute;
    top: pxToRem(56px);
    left: pxToRem(1507px);
    width: pxToRem(152px);
    height: pxToRem(43px);
    border-radius: pxToRem(2px);
    background: rgba(17, 17, 17, 0.5);
    backdrop-filter: blur(pxToRem(2.5px));
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    padding: 0 pxToRem(9px);
    justify-content: space-between;
    align-items: center;
    @mixin font() {
        font-family: Brutal Type;
        font-style: normal;
        line-height: normal;
    }
    .penaltyBlock {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
    }
    .title {
        @include font();
        color: var(--whiteBase);
        font-size: pxToRem(10px);
        font-weight: 500;
    }
    .data {
        @include font();
        color: #c8c7c7;
        font-size: pxToRem(10px);
        font-weight: 400;
    }
    .time {
        align-self: flex-end;
    }
}
.hideWidget {
    display: none;
}
