@import '@/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.mainInfo {
    position: absolute;
    top: pxToRem(148px);
    left: pxToRem(171px);
    width: pxToRem(320px);
    height: pxToRem(118px);
    background: #2f9356;
}
.mainInfo div:first-child {
    margin: pxToRem(18px) auto pxToRem(0px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    text-align: center;
    color: var(--whiteBase);
}
.mainInfo div:last-child {
    margin: pxToRem(15px) auto pxToRem(0px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(36px);
    line-height: pxToRem(42px);
    text-align: center;
    color: var(--whiteBase);
}
