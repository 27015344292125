@import 'src/shared/styles/pxToRem';

$container-width: 1920px;
$container-height: 1080px;

$box1-padding-left: 55px;
$box1-padding-top: 213px;

$box2-padding-top: 6px;
$box2-padding-left: 55px;

$select-padding-top: 213px;
$select-padding-right: 55px;

$title-font-size: 36px;
$title-line-height: 42px;
$title-padding-top: 60px;

$timer-padding-left: 76px;
$timer-padding-bottom: 72px;

$button-margin-right: 60px;
$button-margin-bottom: 70px;
$scale: 1;

$bg-img: url('~/src/shared/images/bg-menu.png');

.container {
    width: pxToRem($container-width, $scale);
    height: pxToRem($container-height, $scale);
    background: $bg-img;
    background-size: pxToRem($container-width, $scale)
        pxToRem($container-height, $scale);
    position: relative;
}

.box1 {
    padding-left: pxToRem($box1-padding-left, $scale);
    padding-top: pxToRem($box1-padding-top, $scale);
    position: relative;
    max-width: fit-content;
}

.box2 {
    padding-top: pxToRem($box2-padding-top, $scale);
    padding-left: pxToRem($box2-padding-left, $scale);
}

.select {
    float: right;
    padding-top: pxToRem($select-padding-top, $scale);
    padding-right: pxToRem($select-padding-right, $scale);
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem($title-font-size, $scale);
    line-height: pxToRem($title-line-height, $scale);
    color: var(--whiteBase);
    text-align: center;
    padding-top: pxToRem($title-padding-top, $scale);
}

.timer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding-left: pxToRem($timer-padding-left, $scale);
    padding-bottom: pxToRem($timer-padding-bottom, $scale);
    max-width: fit-content;
    max-height: fit-content;
}

.button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: pxToRem($button-margin-right, $scale);
    margin-bottom: pxToRem($button-margin-bottom, $scale);
}
