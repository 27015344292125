@import 'src/shared/styles/pxToRem';
.wrapper {
    color: var(--whiteBase);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: var(--blackBase);
    border-radius: 10px;
    border: 1px solid var(--yellow100);
}
.title {
    font-weight: 500;
    margin: 0;
}
.subtitle {
    width: 100%;
    font-weight: 400;
    margin: 0;
    &:nth-child(2) {
        margin-top: 35px;
    }
    &:nth-child(3) {
        margin-top: 15px;
    }
    a {
        color: var(--yellow100);
    }
}
.button {
    display: flex;
    align-self: center;
    margin: 15px 0 auto 0;
    font-weight: bold;
    width: 100%;
}

.content {
    min-width: 1px;
    margin: 0 auto;
    margin-top: 25px;
    display: inline-block;
}

.formErrorMessage {
    font-size: pxToRem(20px);
    margin-top: 10px;
    color: var(--red200);
    text-align: center;
}
