@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import '@/shared/styles/pxToRem.scss';

.cardShape {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--whiteBase);
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    width: pxToRem(184px);
    height: pxToRem(43px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: #e3ad22;
}
.cardShape:hover {
    cursor: pointer;
}
