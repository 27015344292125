@import '@/shared/styles/pxToRem';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    color: var(--whiteBase);
    font-size: pxToRem(18px);
    font-family: Roboto;
    font-weight: medium;
    margin-bottom: pxToRem(10px);
}
.slider_btn {
    &.next_btn {
        rotate: 180deg;
    }
    &.prev_btn {
    }
}
.slider {
    margin: pxToRem(14px) 0 !important;
    width: pxToRem(111px);
    height: pxToRem(480px) !important;
}
.slide {
    cursor: pointer;
}
.slide_content {
    height: 100%;
    background-color: var(--black600);
    overflow: hidden;
    border: 1px solid transparent;
    transition: 0.2s ease border-color;
    img {
        width: 100%;
        height: 100%;
    }
}
.slide_active {
    border-color: var(--yellow100);
}
