@import 'src/shared/styles/pxToRem';

.container {
    width: pxToRem(62px);
    height: pxToRem(57px);
    font-weight: 700;
    font-size: pxToRem(13px);
    line-height: pxToRem(15px);
    letter-spacing: -0.05em;
    color: var(--whiteBase-50);
    position: relative;
}

.launchControl {
    position: absolute;
    bottom: 0;
    right: 0;
}

.active {
    color: var(--whiteBase);
}
