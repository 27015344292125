@import 'src/shared/styles/pxToRem';

@keyframes activeKey{
    0%{
        background:rgba(17, 17, 17, 0.50);
        box-shadow:none;
    }
    50%{
        background:rgba(255, 234, 71, 0.8);
        box-shadow:pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(1px) rgb(255, 234, 71);
    }
}

.hints {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: pxToRem(10px);

    .wrapper {
        display: flex;
        flex-flow: row nowrap;
        gap: pxToRem(12px);
        align-items: center;
        color: #FFF;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Brutal Type;
        font-style: normal;
        font-weight: 500;

        .key {
            width: pxToRem(40px);
            height: pxToRem(25px);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: pxToRem(2px);
            background: rgba(17, 17, 17, 0.50);
            font-size: pxToRem(14px);
            line-height: pxToRem(19px);
            letter-spacing: pxToRem(-0.14px);
        }
        .activeKey{
            animation: activeKey 2.5s ease-in-out infinite;
        }

        .title {
            font-size: 15px;
            line-height: normal;
        }
    }
}