@import 'src/shared/styles/pxToRem';

@mixin buttonSize($scale: 1) {
    $border-radius: 10px;
    $width: 168px;
    $height: 48px;
    $font-size: 18px;

    border-radius: pxToRem($border-radius, $scale);
    width: pxToRem($width, $scale);
    height: pxToRem($height, $scale);
    font-size: pxToRem($font-size, $scale);
}

.btn {
    padding: 0;
    cursor: pointer;
    border: none;
    margin: 0;
    width: auto;
    overflow: visible;
    font: inherit;
    line-height: normal;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    position: relative;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 0;
    color: var(--black600);

    &XS {
        @include buttonSize(0.8);
    }
    &S {
        @include buttonSize(0.9);
    }
    &M {
        @include buttonSize(1);
    }
    &L {
        @include buttonSize(1.4);
    }

    &Z {
        $scale: 1;
        width: pxToRem(156px, $scale);
        height: pxToRem(47px, $scale);
        border-radius: pxToRem(10px, $scale);
        font-size: pxToRem(18px, $scale);
    }

    &Primary {
        background-color: var(--yellow100);
        color: var(--black600);
    }
    &Secondary {
        background-color: var(--green700);
        color: var(--whiteBase);
    }
    &Tertiary {
        background-color: var(--red400);
        color: var(--whiteBase);
    }
}

.btn:hover {
    opacity: 0.8;
}

.btn:active {
    opacity: 0.5;
}

.full {
    width: 100%;
}

.disabled {
    background-color: var(--white200);
    color: var(--grey500);
}
