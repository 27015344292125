@import '@/shared/styles/pxToRem.scss';
.stepper {
    position: absolute;
    left: 50%;
    translate: -50% 0;
    top: pxToRem(40px);
    width: pxToRem(307px);
    height: pxToRem(98px);
}

.actionButtonsBlock {
    position: absolute;
    top: pxToRem(965px);
    transition: 0.4s ease;
    &.centerPos {
        left: pxToRem(1171px);
    }
    &.leftPos {
        left: pxToRem(230px);
    }
}
