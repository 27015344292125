@import '@/shared/styles/pxToRem.scss';

.cameraRotation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: pxToRem(17px);
    position: absolute;
    top: pxToRem(983px);
    right: pxToRem(82px);

    & img {
        width: pxToRem(22px);
    }

    & div {
        color: var(--whiteBase);
        font-family: Roboto;
        font-size: pxToRem(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
