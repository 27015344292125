@import '@/shared/styles/pxToRem.scss';

.wrapper {
    position: relative;
    width: pxToRem(75px);
    height: pxToRem(86px);
    border-radius: pxToRem(10px);
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    & .selectedIcon {
        width: pxToRem(31px);
        height: pxToRem(31px);
    }

    & .arrow {
        position: absolute;
        top: pxToRem(41px);
        left: pxToRem(57px);
        transform: rotate(90deg);
    }

    & .optionsContainer {
        position: absolute;
        top: pxToRem(89px);
        left: 0;
        width: pxToRem(75px);
        height: fit-content;
        border-radius: pxToRem(10px);
        background: var(--black1100);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        z-index: 2;
    }
}
