@import '@/shared/styles/pxToRem.scss';

@keyframes join {
    0% {
        height: pxToRem(0px);
    }
    100% {
        height: pxToRem(43px);
    }
}

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.mainInfo {
    position: absolute;
    top: pxToRem(118px);
    left: pxToRem(137px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: pxToRem(384px);
}

.header {
    width: 100%;
    height: pxToRem(40px);
    display: flex;
    flex-flow: row nowrap;
}

.header > img {
    width: pxToRem(56px);
    height: pxToRem(40px);
    margin-left: pxToRem(7px);
}

.header > div {
    height: pxToRem(40px);
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    text-align: center;
    color: var(--whiteBase);
}

.cardOrPhone {
    width: 100%;
    height: pxToRem(33px);
    display: flex;
    flex-flow: row nowrap;
    background: rgba(47, 147, 86, 0.2);
}

.cardOrPhoneSelect {
    width: pxToRem(192px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    text-align: center;
    color: #2f9356;
    background: rgba(255, 255, 255, 0.5);
}

.cardOrPhoneSelect:hover {
    cursor: pointer;
}

.whiteBg {
    background-color: var(--whiteBase);
}

.recipient {
    box-sizing: border-box;
    width: 100%;
    height: pxToRem(43px);
    background: #2f9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    margin-top: pxToRem(10px);
    padding-right: pxToRem(7px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.cardLogo {
    display: inline;
    width: pxToRem(56px);
    height: pxToRem(40px);
    margin-right: pxToRem(-14px);
}

.cardLogoHide {
    display: none;
}

.recipientInput {
    margin-left: pxToRem(15px);
    background: transparent;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(21px);
    color: rgba(255, 255, 255, 1);
    border: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.recipientInputHide {
    margin-left: pxToRem(15px);
    background: transparent;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(21px);
    color: rgba(255, 255, 255, 1);
    border: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    opacity: 0;
    z-index: 4;
}

.recipientInput::placeholder,
.valueTransfer::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(12px);
    line-height: pxToRem(14px);
    color: rgba(255, 255, 255, 0.5);
}

.recipientInput:focus,
.valueTransfer:focus {
    outline: none;
}

.phoneMaskBlock {
    margin-left: pxToRem(15px);
    background: url(/shared/assets/images/vectors/ATM/TransferScreen/phoneBookPic.svg)
        no-repeat right center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(21px);
    color: rgba(255, 255, 255, 1);
    border: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: absolute;
    top: pxToRem(0px);
    left: pxToRem(0px);
    width: pxToRem(351px);
    height: pxToRem(43px);
    z-index: 3;
}

.valueTransfer {
    box-sizing: border-box;
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: pxToRem(43px);
    background: #2f9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    margin-top: pxToRem(10px);
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: var(--whiteBase);
    padding-left: pxToRem(10px);
    opacity: 1;
}

.valueTransferHide {
    box-sizing: border-box;
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: pxToRem(43px);
    background: #2f9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    margin-top: pxToRem(10px);
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: var(--whiteBase);
    padding-left: pxToRem(10px);
    opacity: 0;
}

.transferValueMask {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: pxToRem(43px);
    position: absolute;
    bottom: pxToRem(0px);
    left: pxToRem(0px);
    z-index: 1;
    background: #2f9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    margin-top: pxToRem(10px);
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: var(--whiteBase);
    padding-left: pxToRem(10px);
}

.transferButton {
    position: absolute;
    width: pxToRem(227px);
    height: pxToRem(43px);
    left: pxToRem(216px);
    bottom: pxToRem(38px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--whiteBase);
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: #e3ad22;
}
.transferButton:hover {
    cursor: pointer;
}

.phoneBookContainer {
    position: relative;
    width: pxToRem(384px);
    height: pxToRem(43px);
    background: #84dda8;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.12);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    animation: join 0.5s;
    overflow: hidden;
}

.phoneBookContainer div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(21px);
    color: var(--whiteBase);
    margin: pxToRem(0px) pxToRem(0px) pxToRem(0px) pxToRem(18px);
}
.phoneBookContainer div:last-child {
    font-size: pxToRem(12px);
    margin: pxToRem(0px) pxToRem(20px) pxToRem(0px) pxToRem(0px);
}
