@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: 100%;
    height: 100vh;
    position: relative;

    & .playerInfoForm {
        position: absolute;
        top: pxToRem(110px);
        left: pxToRem(31px);
    }
}
