@import "src/shared/styles/pxToRem";

.input {
    position: relative;
}

.capsBlock {
    position: absolute;
    right: 0;
    transform: translate(100%, -50%);
    top: 50%;
    font-size: pxToRem(18px);
    font-weight: 500;
    line-height: pxToRem(21px);
    color: rgb(255, 216, 14);
    padding-left: pxToRem(10px);
}
