.deathScreen {
    width: 1920px;
    height: 1080px;
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.radius1 {
    width: 100%;
    height: 200%;
    background: radial-gradient(
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0.9) 70%
        ),
        radial-gradient(
            circle,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.6) 50%,
            rgba(0, 0, 0, 0.9) 80%
        );
}

.info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.death {
    color: #96422c;
    text-align: center;
    font-family: Brutal Type;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.person {
    color: #ccc8c6;
    text-align: center;
    font-family: Brutal Type;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 10px auto 77px;
}

.btn {
    width: 208px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    color: var(--blackBase);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: var(--yellow100);
    color: var(--black600);
    border-radius: 5px;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.5;
    }
}
