@import 'src/shared/styles/pxToRem';

.mainHeaderTools {
    display: flex;
    flex-flow: row nowrap;
    gap: pxToRem(10px);
    margin-right: pxToRem(20px);
}

.searchForm {
    position: relative;
}

.clearButton {
    position: absolute;
    width: pxToRem(38px);
    height: pxToRem(38px);
    top: 0;
    right: 0;
    background: transparent
        url(/shared/assets/images/vectors/Store/Input/clearButtonPic.svg) center
        no-repeat;
    border: 0;
}

.clearButton:hover {
    cursor: pointer;
}

.search {
    width: pxToRem(223px);
    height: pxToRem(38px);
    border: pxToRem(1px) solid var(--whiteBase);
    border-radius: pxToRem(10px);
    background: transparent;
    box-sizing: border-box;
    padding-left: pxToRem(18px);
    color: var(--whiteBase);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
}

.search::placeholder {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: #939393;
}

.search:focus {
    outline: none;
}

//------------color select-------------------//

.colorSelectWrapper {
    position: relative;
    z-index: 10;
}

.colorSelect {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: pxToRem(185px);
    height: pxToRem(38px);
    border: pxToRem(1px) solid var(--whiteBase);
    border-radius: pxToRem(10px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
    appearance: none;
    padding-left: pxToRem(15px);
    padding-right: pxToRem(14px);
}

.colorSelect:hover {
    cursor: pointer;
}

.arrow {
    position: absolute;
    width: pxToRem(12px);
    height: pxToRem(38px);
    top: pxToRem(0px);
    right: pxToRem(14px);
}

.openColorSelect {
    position: absolute;
    top: pxToRem(41px);
    left: 0;
    width: pxToRem(185px);
    height: fit-content;
    box-sizing: border-box;
    background: var(--blackBase);
    border: pxToRem(1px) solid $borderColor;
    border-radius: pxToRem(10px);
    display: grid;
    gap: pxToRem(7px);
    grid-template-columns:
        pxToRem(20px) pxToRem(20px) pxToRem(20px) pxToRem(20px)
        pxToRem(20px) pxToRem(20px);
    padding: pxToRem(15px) pxToRem(16px) pxToRem(15px) pxToRem(16px);
}

.optionColor {
    box-sizing: border-box;
    border: pxToRem(1px) solid var(--whiteBase);
    width: pxToRem(20px);
    height: pxToRem(20px);
}

.optionColor:hover {
    cursor: pointer;
}
