@import '@/shared/styles/pxToRem.scss';

.sharedStyles {
    position: absolute;
    top: 50%;
    left: 50%;
    aspect-ratio: 1/1;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-sizing: border-box;
}

.target,
.targetBlur,
.circle {
    @extend .sharedStyles;
}

.wrapper {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, 0);
    width: pxToRem(100px);
    height: pxToRem(100px);
    .target {
        width: pxToRem(140px);
        border: pxToRem(10px) solid rgba(0, 255, 0, 0.9);
    }
    .circle {
        width: pxToRem(300px);
        border: pxToRem(5px) solid var(--whiteBase);
    }
}
