@import '@/shared/styles/pxToRem';
.wrapper {
    padding: pxToRem(15px);
    padding-top: pxToRem(30px);
    border-left: none;
    border-right: none;
}
.title {
    color: var(--whiteBase);
    font-size: pxToRem(18px);
    font-family: Roboto;
    font-weight: medium;
    margin-bottom: pxToRem(30px);
    text-align: center;
}
