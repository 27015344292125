@import '@/shared/styles/pxToRem';

.wrapper {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    right: pxToRem(150px);
    display: flex;
}
.title {
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
    size: pxToRem(18px);
    line-height: 100%;
    color: var(--whiteBase);
    margin-bottom: pxToRem(20px);
}
.variants_wrapper {
    width: pxToRem(410px);

    .group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: auto;
        aspect-ratio: 1;
        .item {
            aspect-ratio: 1;
        }
    }
}
.colors_wrapper {
    padding: 0;
    width: pxToRem(185px);
    .group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: pxToRem(8px);
        aspect-ratio: 1;
        overflow-x: clip;
        overflow-y: auto;
    }
    .item {
        aspect-ratio: 1;
    }
}
.main_color {
    border-left: none;
    border-bottom: pxToRem(15px) solid var(--blackBase-50);
    padding: pxToRem(15px);
}
.additional_colors {
    border-top: none;
    border-left: none;
}
