@import '@/shared/styles/pxToRem.scss';

.plateReaderFrame {
    font-family: 'Heebo', Verdana, Geneva, Tahoma, sans-serif;
    font-size: pxToRem(13px);
    box-sizing: border-box;
    user-select: none;

    width: pxToRem(500px);
    height: pxToRem(200px);

    position: absolute;
    margin: auto;

    top: calc(50% - (pxToRem(200px) / 2));
    left: calc(100% - pxToRem(500px));

    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/pr_frame.png);
    background-size: contain;

    transition: transform 0.5s;

    /* Settings for scaling */
    transform: scale(1);
    transform-origin: 0 0;

    z-index: 10;
}

.plateReaderFrame .frame_border {
    width: pxToRem(465px);
    height: pxToRem(175px);

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgb(20, 22, 18);

    border-radius: pxToRem(5px);
}

.plateReader {
    width: pxToRem(460px);
    height: pxToRem(170px);

    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/pr_bg.webp);

    box-shadow: inset pxToRem(0px) pxToRem(20px) pxToRem(20px) - pxToRem(15px)
        rgba(0, 0, 0, 0.4);

    display: grid;
    grid-template-rows: pxToRem(30px) 1fr pxToRem(30px);
    align-content: center;
}

.plateReader .labels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
}

.plateReader .labels .title {
    color: rgb(255, 255, 255);
}

.plateReader .labels p {
    margin: 0;
    text-align: center;
    font-size: pxToRem(18px);
}

.plateReader .plates {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-items: center;
}

.plateReader .plates .plate_container {
    width: 90%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
}

.plateReader .plates .plate_container .plate {
    display: block;
    max-width: 100%;
    height: auto;
    grid-column: 1;
    grid-row: 1;
}

.plateReader .plates .plate_container .text_container {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
}

.plateReader .plates .plate_container .text_container p {
    display: block;
    font-family: 'Plate-Font';
    font-size: pxToRem(58px);
    text-align: center;
    letter-spacing: pxToRem(-3px);
    padding-top: pxToRem(5px);
    margin: 0;
    grid-column: 1;
    grid-row: 1;
}

.plateReader .plates .plate_container .text_container .hilite {
    font-family: 'Plate-Font-Hilite';
    color: rgb(93, 65, 255);
}

.plateReader .plates .plate_container .text_container .lolite {
    font-family: 'Plate-Font-Lolite';
    color: rgb(255, 255, 255);
}

.plateReader .plates .plate_container .text_container .shadow {
    font-family: 'Plate-Font-Shadow';
    color: rgb(100, 100, 100);
}

.active {
    color: rgb(242, 70, 24);
    text-shadow: 0 0 pxToRem(6px) rgba(235, 62, 15, 0.5);
}
