@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(63px);
    height: pxToRem(64px);
    border-radius: pxToRem(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:first-child {
        margin-top: pxToRem(6px);
    }

    &:last-child {
        margin-bottom: pxToRem(6px);
    }

    & img {
        width: pxToRem(37px);
        height: pxToRem(37px);
    }
    &:hover .tooltip {
        display: flex;
    }
    .tooltip {
        position: absolute;
        top: pxToRem(12px);
        left: pxToRem(67px);
        width: fit-content;
        display: none;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: pxToRem(11px) pxToRem(13px);
        border-radius: pxToRem(10px);
        border: pxToRem(1px) solid var(--yellow100);
        background: var(--blackBase);
        color: var(--whiteBase);
        font-family: Roboto;
        font-size: pxToRem(14px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        z-index: 10;
        white-space: nowrap;
    }
}

.selected {
    background: var(--blackBase-50);
}
