@import '@/shared/styles/pxToRem';

.wrapper {
    padding: pxToRem(15px) pxToRem(15px) pxToRem(23px) pxToRem(15px);
}
.title {
    color: var(--whiteBase);
    font-size: pxToRem(18px);
    font-family: Roboto;
    font-weight: medium;
    margin-bottom: pxToRem(10px);
    text-align: center;
}
.group {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: pxToRem(8px);
    row-gap: pxToRem(5px);
    overflow: auto;
    max-height: pxToRem(130px);
    &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--black100);
    }
}
.item {
    cursor: pointer;
    background-color: var(--blackBase);
    position: relative;
    &::before {
        content: '';
        position: absolute;
        transition: 0.2s ease;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        border-radius: 50%;
        background-color: var(--black200);
        width: 50%;
        height: 50%;
        opacity: 0;
    }
}
.active {
    &::before {
        opacity: 1;
    }
}
.slider_inp {
    margin-top: pxToRem(30px);
}
