@import '@/shared/styles/pxToRem';

.wrapper {
    position: absolute;
    right: pxToRem(245px);
    top: 50%;
    translate: 0 -50%;
    z-index: 2;
    display: flex;
}
