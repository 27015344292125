@import 'src/shared/styles/pxToRem';

.selectWrapper {
    position: relative;
    z-index: 10;
}

.select {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: pxToRem(220px);
    height: pxToRem(38px);
    border: pxToRem(1px) solid var(--whiteBase);
    border-radius: pxToRem(10px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
    appearance: none;
    padding-left: pxToRem(15px);
    padding-right: pxToRem(14px);
}

.select:hover {
    cursor: pointer;
}

// .select::after {
//     content: '';
//     display: block;
//     position: absolute;
//     display: block;
//     width: pxToRem(12px);
//     height: pxToRem(38px);
//     top: pxToRem(0px);
//     right: pxToRem(14px);
//     background: url(./assets/selectArrowPic.svg) center no-repeat;
// }

.arrow {
    position: absolute;
    width: pxToRem(12px);
    height: pxToRem(38px);
    top: pxToRem(0px);
    right: pxToRem(14px);
}

.openSelect {
    position: absolute;
    top: pxToRem(41px);
    left: 0;
    width: pxToRem(220px);
    height: fit-content;
    overflow: hidden;
    box-sizing: border-box;
    background: var(--blackBase);
    border: pxToRem(1px) solid $borderColor;
    border-radius: pxToRem(10px);
}

.selectOption {
    width: pxToRem(220px);
    height: pxToRem(40px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
    box-sizing: border-box;
    padding-left: pxToRem(15px);
    display: flex;
    align-items: center;
}

.selectOption:hover {
    cursor: pointer;
}

.selectOptionSelected {
    background: $backColor;
    color: $fontColor;
}
