@import '@/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.pinCodeInput {
    box-sizing: border-box;
    border: none;
    position: absolute;
    z-index: 2;
    top: pxToRem(169px);
    left: pxToRem(0px);
    margin: pxToRem(0px) pxToRem(12px) pxToRem(0px) pxToRem(12px);
    background: #2f9356;
    display: block;
    width: pxToRem(638px);
    height: pxToRem(54px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(30px);
    line-height: pxToRem(35px);
    letter-spacing: pxToRem(15px);
    color: var(--whiteBase);
    text-align: center;
    opacity: 0;
}

.pinCodeInput:focus {
    outline: none;
    caret-color: transparent;
}

.pinCodeInput::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(30px);
    line-height: pxToRem(35px);
    letter-spacing: pxToRem(15px);
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.mask {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: pxToRem(169px);
    left: pxToRem(0px);
    margin: pxToRem(0px) pxToRem(12px) pxToRem(0px) pxToRem(12px);
    padding-left: pxToRem(10px);
    background: #2f9356;
    display: flex;
    justify-content: center;
    align-items: center;
    width: pxToRem(638px);
    height: pxToRem(54px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(30px);
    line-height: pxToRem(35px);
    letter-spacing: pxToRem(15px);
    color: var(--whiteBase);
    text-align: center;
}

.screenAlert {
    position: absolute;
    width: pxToRem(259px);
    height: pxToRem(81px);
    left: pxToRem(201px);
    top: pxToRem(248px);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.screenAlertError {
    box-sizing: border-box;
    padding: pxToRem(0px) pxToRem(41px) pxToRem(0px) pxToRem(41px);
    position: absolute;
    width: pxToRem(198px);
    height: pxToRem(66px);
    left: pxToRem(232px);
    top: pxToRem(248px);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.screenAlertText {
    width: pxToRem(220px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    text-align: center;
    color: var(--whiteBase);
}

.screenAlertTextError {
    width: 220px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    text-align: center;
    color: #d13030;
}
