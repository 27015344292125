@import 'src/shared/styles/pxToRem';

.description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: pxToRem(190px);
    height: pxToRem(53px);
    padding: 0 pxToRem(18px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(16px);
    color: var(--whiteBase);
}

.image {
    width: pxToRem(92px);
    height: pxToRem(72px);
}

.imageContainer {
    width: pxToRem(190px);
    height: pxToRem(72px);
    position: absolute;
    top: pxToRem(57px);
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.imageTattoo {
    width: 100%;
    height: 100%;
}

.imageTattooFiltered {
    width: 100%;
    height: 100%;
    filter: brightness(30%) contrast(70%);
}

.imageContainerTattoo {
    position: absolute;
    top: pxToRem(0px);
    left: pxToRem(0px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.mainShape {
    box-sizing: border-box;
    border: pxToRem(2px) solid transparent;
    border-bottom: pxToRem(2px) solid $borderColor;
    width: pxToRem(190px);
    height: pxToRem(220px);
    background: rgba(75, 75, 75, 0.35);
    backdrop-filter: blur(pxToRem(5px));
    position: relative;
}

.mainShape:hover {
    cursor: pointer;
}

.mainShapeHover {
    border: pxToRem(2px) solid $borderColor;
}

.price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: var(--whiteBase);
}

.priceContainer {
    position: absolute;
    top: pxToRem(10px);
    left: pxToRem(10px);
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    width: fit-content;
    height: fit-content;
}

.cardTitle {
    position: absolute;
    top: pxToRem(3px);
    left: pxToRem(3px);
    z-index: 2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    height: fit-content;
    gap: pxToRem(5px);
}

.cardTitle > span {
    font-family: Roboto;
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: pxToRem(19px);
    letter-spacing: 0em;
    text-align: left;
    color: var(--whiteBase);
}

.icon {
    width: pxToRem(17px);
    height: pxToRem(17px);
}

.toCart {
    position: absolute;
    bottom: 0;
    left: 0;
    width: pxToRem(188px);
    height: pxToRem(53px);
    background: $backColor;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: var(--blackBase);
    display: flex;
    justify-content: center;
    align-items: center;
}

.addToCart {
    position: absolute;
    bottom: 0;
    left: 0;
    width: pxToRem(188px);
    height: pxToRem(53px);
    background: $backColor;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: $fontColor;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: pxToRem(3px);
}

.valueButton {
    width: pxToRem(25px);
    height: pxToRem(25px);
    background: #3e3d3d;
    backdrop-filter: blur(pxToRem(25px));
    border-radius: pxToRem(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Brutal Type';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14px);
    line-height: pxToRem(17px);
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--whiteBase);
}

.valueButton:hover {
    cursor: pointer;
}

.productValue {
    width: pxToRem(25px);
    height: pxToRem(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Brutal Type';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14px);
    line-height: pxToRem(17px);
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--blackBase);
}

.oldPrice {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(12px);
    line-height: pxToRem(14px);
    text-decoration-line: line-through;
    color: var(--whiteBase);
}

.priceSale {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: #df1c23;
}

.saleLabel {
    position: absolute;
    top: pxToRem(9px);
    right: pxToRem(0px);
    width: pxToRem(64px);
    height: pxToRem(22px);
    background: #df1c23;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(16px);
    color: var(--whiteBase);
}

.greenBackground {
    position: absolute;
    bottom: 0;
    left: 0;
    width: pxToRem(188px);
    height: pxToRem(53px);
    background: $backColor;
}
