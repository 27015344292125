:root {
    --whiteBase: #fff;
    --whiteBase-50: #ffffff80;
    --whiteBase-80: #ffffffcc;
    --whiteBase-95: #fffffff2;
    --white100: #dbcdc1;
    --white200: #d1d5d8;

    --blackBase: #000;
    --blackBase-50: #00000080;
    --blackBase-80: #000000cc;
    --black100: #444;
    --black200: #232323;
    --black300: #202020;
    --black300-95: #202020f2;
    --black400: #313131;
    --black500: #2c2c2c;
    --black500-80: #2c2c2ccc;
    --black600: #121212;
    --black700: #343333;
    --black800: #141619;
    --black900: #1a1a1c;
    --black1000: #262626;
    --black1100: #393939;
    --black1200: #2f2f2f;

    --blue100: #3a547f;
    --blue200: #253f84;
    --blue300: #075492;
    --blue400: #0756cf;
    --blue500: #0e408b;
    --blue600: #1c355e;
    --blue700: #334a6e;

    --sand100: #cfc5a8;
    --sand200: #7e765f;

    --yellow100: #ffd80e;
    --yellow200: #eff2a3;

    --dirty100: #5b4e3f;

    --sky100: #558fc2;
    --sky200: #a3b4da;

    --purple100: #5c517d;

    --pink100: #533b4f;
    --pink200: #361f2c;
    --pink300: #4c3747;

    --red100: #ad322d;
    --red200: #fe4545;
    --red300: #dd5757;
    --red400: #e44747;

    --brown100: #a52a2a;

    --grey100: #888;
    --grey200: #565656;
    --grey300: #a5a5a5;
    --grey300-25: #a5a5a540;
    --grey400: #aaaaaa;
    --grey500: #949494;
    --grey700: #737373;
    --grey800: #686868;
    --grey900: #484848;
    --grey1000: #b0b0b0;
    --grey1100: #515356;

    --green100: #195b27;
    --green200: #5bc06e;
    --green300: #3ca62b;
    --green400: #85ff83;
    --green500: #2fa92c;
    --green600: #6aa84f;
    --green700: #219a43;
    --green800: #43553b;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    font-size: 1.482vmin; /* 16px with 1080px resolution */
}
.has-main-scrollbar-v {
    &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--black1100);
    }
}
