@import '@/shared/styles/pxToRem';
.wrapper {
    position: absolute;
    right: pxToRem(285px);
    top: 50%;
    translate: 0 -50%;
    min-width: pxToRem(400px);
    min-height: pxToRem(545px);
}
.title {
    text-align: center;
    font-size: pxToRem(18px);
    color: var(--whiteBase);
    font-family: Roboto;
    font-weight: 500;
}
.group {
    margin-top: pxToRem(12px);
    display: flex;
    flex-direction: column;
    row-gap: pxToRem(5px);
}
.item {
    padding-bottom: pxToRem(40px);
}
.item_title {
    width: 100%;
    background-color: var(--black900);
    padding: pxToRem(12px) 0;
    text-align: center;
    margin-bottom: pxToRem(25px);
    font-size: pxToRem(16px);
    color: var(--whiteBase);
    font-family: Roboto;
    font-weight: 500;
}
