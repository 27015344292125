/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
figure,
blockquote,
dl,
dd {
    font-weight: unset;
    margin: 0;
    white-space: break-spaces;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
    list-style: none;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
    line-height: 1.1;
}

::marker {
    display: none;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-size: inherit;
    font-family: inherit;
    white-space: break-spaces;
}
button {
    cursor: pointer;
    display: block;
    background-color: transparent;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}
a {
    text-decoration: none;
    color: inherit;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}
