@import '@/shared/styles/pxToRem.scss';

@mixin font() {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .paymentSuccess {
        width: pxToRem(45px);
    }
    .text {
        @include font();
        margin: pxToRem(10px) 0 0 0;
        color: var(--grey800);
        font-size: pxToRem(10px);
    }
    .value {
        @include font();
        margin: pxToRem(15px) 0 0 0;
        color: var(--grey900);
        font-size: pxToRem(16px);
    }
}
