@import '@/shared/styles/pxToRem.scss';

.rc {
    font-family: 'Heebo', Verdana, Geneva, Tahoma, sans-serif;
    font-size: pxToRem(13px);
    box-sizing: border-box;
    user-select: none;

    width: pxToRem(315px);
    height: pxToRem(800px);

    position: absolute;
    top: calc(50% - (pxToRem(800px) / 2));
    left: calc(50% - (pxToRem(315px) / 2));
    margin: auto;
    padding: pxToRem(65px) pxToRem(30px) pxToRem(50px) pxToRem(30px);

    display: grid;
    grid-template-rows:
        pxToRem(50px) pxToRem(30px) pxToRem(200px) pxToRem(30px) pxToRem(60px)
        pxToRem(130px) pxToRem(50px) pxToRem(80px);
    justify-items: center;
    align-items: center;

    color: var(--whiteBase);
    background-image: url(/shared/assets/images/illustrations/Hud/PoliceRadar/rc_bg.webp);
    background-size: contain;

    transition: transform 0.5s;

    /* Settings for scaling */
    transform: scale(1);
    transform-origin: 0 0;

    z-index: 13;
}
/* Button template classes */
.rc button {
    border: none;
    margin: 0;
    padding: 0;

    background-color: rgb(200, 200, 200);

    box-shadow: pxToRem(2px) pxToRem(3px) rgb(100, 100, 100);
}

.rc button:hover {
    background-color: rgb(230, 230, 230);
}

.rc button:active {
    box-shadow: none;
    transform: translateX(pxToRem(2px)) translateY(pxToRem(3px));
}

.rc .rounded_btn {
    border-radius: pxToRem(30px);
}

.rc .circle_btn {
    border-radius: 100%;
}

.rc .zone_btn {
    width: pxToRem(65px);
    height: pxToRem(65px);
}

.rc .xmit_btn {
    width: pxToRem(65px);
    height: pxToRem(80px);
    position: relative;
    background-color: rgb(200, 200, 200);
}

.rc .xmit_btn:hover {
    background-color: rgb(230, 230, 230);
}
.rc .xmit_top {
    clip-path: polygon(
        pxToRem(0px) pxToRem(15px),
        50% pxToRem(0px),
        100% pxToRem(15px),
        100% 100%,
        pxToRem(0px) 100%
    );
    border-radius: pxToRem(0px) pxToRem(0px) pxToRem(7px) pxToRem(7px);
}

.rc .xmit_bottom {
    clip-path: polygon(
        pxToRem(0px) pxToRem(65px),
        pxToRem(0px) pxToRem(0px),
        100% pxToRem(0px),
        100% pxToRem(65px),
        50% 100%
    );
    border-radius: pxToRem(7px) pxToRem(7px) pxToRem(0px) pxToRem(0px);
}

.rc .xmit_wrap {
    filter: drop-shadow(pxToRem(2px) pxToRem(3px) rgb(100, 100, 100));
}

.rc .xmit_wrap:active {
    filter: drop-shadow(0 0 rgba(100, 100, 100, 0));
    transform: translateX(pxToRem(2px)) translateY(pxToRem(3px));
}
.rc .xmit_wrap button:active {
    transform: none;
}

.rc .xmit_btn .arrow {
    width: pxToRem(40px);
    height: pxToRem(20px);
    position: absolute;
    left: pxToRem(13px);
    right: pxToRem(0px);
    clip-path: polygon(
        50% 0,
        100% 60%,
        70% 60%,
        70% 100%,
        30% 100%,
        30% 60%,
        0 60%
    );
    background-color: var(--blackBase);
}
.rc .xmit_btn .arrow_bottom {
    transform: rotate(180deg);
    bottom: pxToRem(2px);
}
.rc .xmit_btn .arrow_top {
    top: pxToRem(2px);
}

/* Buttons and remote control elements */
.rc .toggle_display {
    width: pxToRem(130px);
    height: pxToRem(40px);
}

.rc .label {
    font-size: pxToRem(20px);
    letter-spacing: pxToRem(1px);
}

.rc .antenna_btns_container {
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-rows: 45% 10% 45%;
    align-items: center;
}
.rc .antenna_btns_container .btns {
    display: flex;
    justify-content: space-evenly;
}
.rc .antenna_btns_container .btns_top {
    align-items: flex-end;
}

.rc .antenna_btns_container .btns_bottom {
    align-items: flex-start;
}

/* Style for the button inside the div, this is so the button still works */
.rc .antenna_btns_container .btns .xmit_btn button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: transparent;
}

.rc .antenna_btns_container .btns .top_left {
    border-radius: pxToRem(35px) pxToRem(7px) pxToRem(7px) pxToRem(7px);
}

.rc .antenna_btns_container .btns .xmit_btn .top_middle {
    padding-top: pxToRem(25px);
}

.rc .antenna_btns_container .btns .top_right {
    border-radius: pxToRem(7px) pxToRem(35px) pxToRem(7px) pxToRem(7px);
}

.rc .antenna_btns_container .btns .bottom_left {
    border-radius: pxToRem(7px) pxToRem(7px) pxToRem(7px) pxToRem(35px);
}

.rc .antenna_btns_container .btns .xmit_btn .bottom_middle {
    padding-bottom: pxToRem(25px);
}

.rc .antenna_btns_container .btns .bottom_right {
    border-radius: pxToRem(7px) pxToRem(7px) pxToRem(35px) pxToRem(7px);
}

.rc .antenna_btns_container .breaker {
    width: 95%;
    height: pxToRem(5px);
    margin: auto;
    background-color: var(--whiteBase);
}

.rc .menu {
    width: pxToRem(60px);
    height: pxToRem(60px);
    font-size: pxToRem(17px);
    line-height: pxToRem(62px);
}

.rc .plate_reader_and_help_container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}
.rc .plate_reader_and_help_container .plate_reader,
.rc .plate_reader_and_help_container .help {
    width: pxToRem(80px);
    height: pxToRem(55px);
    border-radius: pxToRem(7px);
}

.rc .plate_reader_and_help_container .help {
    padding: 0 pxToRem(10px);
    font-size: pxToRem(15px);
}

.rc .light {
    width: pxToRem(120px);
    height: pxToRem(30px);
    font-size: pxToRem(15px);
}

.rc .logo {
    font-size: pxToRem(18px);
    font-style: italic;
}
.rc .logo .large {
    font-size: pxToRem(25px);
}

.rc .blue {
    background-color: rgb(84, 210, 255);
}
