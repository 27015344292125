@import 'src/shared/styles/pxToRem';
.wrapper {
    color: var(--whiteBase);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: pxToRem(30px);
    background-color: var(--blackBase);
    border-radius: pxToRem(10px);
    border: 1px solid var(--yellow100);
}
.title {
    font-weight: 500;
    margin: 0;
}
.subtitle {
    width: 100%;
    font-weight: 400;
    margin: 0;
    margin-top: pxToRem(35px);
    text-align: center;
    span {
        color: var(--yellow100);
    }
}
.content {
    margin-top: pxToRem(25px);
}
.button {
    margin-top: pxToRem(15px);
    width: 100%;
}
