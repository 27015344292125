@import "src/shared/styles/pxToRem";

.container {
    display: flex;
}

.tab {
    padding: pxToRem(9px) pxToRem(22px);
    color: rgb(109, 109, 109);
    border-bottom: pxToRem(1px) solid rgb(109, 109, 109);
    cursor: pointer;
    font-size: pxToRem(18px);
    font-weight: 500;
    line-height: pxToRem(21px);
}

.activeTab {
    color: rgb(255, 255, 255);
    border-bottom: pxToRem(3px) solid rgb(255, 216, 14);
}

