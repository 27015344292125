@import 'src/shared/styles/pxToRem';

.container {
    width: 100%;
}

.buttonsBlock {
    display: flex;
    gap: pxToRem(12px);
}
.formErrorMessage {
    font-size: pxToRem(20px);
    margin-top: 10px;
    color: var(--red200);
}
