@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(150px);
    height: pxToRem(150px);
    background: var(--black1000);
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    border: pxToRem(1px) solid transparent;
    position: relative;
    transition: 0.2s;

    .imgContainer {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            height: pxToRem(94px);
        }
    }

    .title {
        width: 100%;
        height: pxToRem(43px);
        background: var(--black400);
        color: var(--whiteBase);
        font-family: Roboto;
        font-size: pxToRem(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.hover {
    transition: 0.2s;
    &:hover {
        border: pxToRem(1px) solid var(--yellow);
        transition: 0.2s;
    }
}
