@import 'src/shared/styles/pxToRem';
.wrapper {
    color: var(--whiteBase);
    display: flex;
    flex-direction: column;
    padding: pxToRem(30px);
    background-color: var(--blackBase);
    border-radius: pxToRem(10px);
    border: pxToRem(1px) solid var(--yellow100);
    width: 100%;
}

.title {
    text-align: center;
    font-weight: 500;
    margin: 0;
}
.subtitle {
    text-align: center;
    font-weight: 400;
    margin-top: pxToRem(35px);
}

.password_validate_content {
    margin-top: 20px;
    margin-bottom: pxToRem(10px);
    display: flex;
    align-items: center;
    column-gap: pxToRem(15px);
}
.inputs {
}
.difficulty_bar {
    display: flex;
    align-items: center;
    column-gap: pxToRem(5px);
    h3 {
        margin-left: pxToRem(18px);
    }
    &.green {
        --active-item-color: #0eff6a;
    }
    &.orange {
        --active-item-color: var(--yellow100);
    }
    &.red {
        --active-item-color: #ff520e;
    }
}
.difficulty_item {
    height: pxToRem(2px);
    width: pxToRem(34px);
    background-color: var(--whiteBase) 41;
    &.active {
        background-color: var(--active-item-color);
    }
}
.button {
    display: flex;
    align-self: center;
    margin: pxToRem(15px) 0 auto 0;
    font-weight: bold;
    width: pxToRem(360px);
}
.caps_lock_state {
    font-weight: 400;
    font-size: pxToRem(18px);
}

.caps_lock_state {
    opacity: 0.6;
    transition: 0.1s ease;
    transition-property: opacity color;
    &.active {
        opacity: 1;
        color: var(--yellow100);
    }
}

.formErrorMessage {
    font-size: pxToRem(20px);
    margin-top: 10px;
    color: var(--red200);
}
