@import 'src/shared/styles/pxToRem';

.messageNormalName {
    color: rgb(221, 221, 221);
}
.messageNormalText {
    color: rgb(181, 181, 181);
}

.messageMe {
    color: #be9ed5;
}

.messageDo {
    color: #f5b365;
}

.messageTodoStart {
    color: var(--whiteBase);
}
.messageTryGood {
    color: green;
}
.messageTryBad {
    color: red;
}

.messageS {
    color: rgb(255, 255, 255);
}

.messageW {
    color: rgb(31, 31, 31);
}

//-------------------------------------------------------------
.message {
    font-variant-numeric: lining-nums proportional-nums;
    -webkit-text-stroke-width: 0.10000000149011612;
    -webkit-text-stroke-color: var(--blackBase);
    font-family: 'Brutal Type';
    font-size: pxToRem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: pxToRem(-0.14px);
    -webkit-text-stroke-width: pxToRem(0.5px);
    -webkit-text-stroke-color: var(--blackBase);
    overflow-wrap: break-word;
}

.rpText {
    color: var(--whiteBase);
    &::first-letter {
        text-transform: uppercase;
    }
}
.rpName {
    color: #d7d7d7;
}
.meText {
    color: #be9ed5;
    &::first-letter {
        text-transform: lowercase;
    }
}
.meName {
    color: #be9ed5;
}
.do {
    color: #f5b365;
}
.toDoText {
    color: #fbfbfa;
}
.toDoName {
    color: #be9ed5;
}
.b {
    color: #d7d7d7;
}
.s {
    color: #fbfbfa;
}
.w {
    color: #fbfbfa;
}
.error {
    color: #f5b365;
    white-space: pre-wrap;
}

.errorMessage {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: pxToRem(9px);
    .errorIcon {
        margin-top: pxToRem(3px);
        width: pxToRem(15px);
        height: pxToRem(15px);
    }
}
