@import '@/shared/styles/pxToRem';

.wrapper {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    width: pxToRem(210px);
    border: pxToRem(15px) solid var(--blackBase-50);
    background-color: var(--black300);
    border-left: none;
}
