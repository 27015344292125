.wrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    padding: 20px;
    overflow: auto;
    cursor: pointer;
    z-index: 2;
}
.content_wrapper {
    display: flex;
    align-items: center;
    min-height: 100%;
    pointer-events: none;
    max-width: 800px;
    margin: 0 auto;
}
.content {
    width: 100%;
    pointer-events: auto;
    cursor: default;
}
