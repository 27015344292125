@import '@/shared/styles/pxToRem.scss';

.navBlock {
    width: pxToRem(268px);
    height: pxToRem(121px);
    background: var(--whiteBase);
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px)
        rgba(0, 0, 0, 0.29);
    position: relative;
    overflow: hidden;
}

.navBlock:hover {
    cursor: pointer;
}

.navTitle {
    position: absolute;
    top: pxToRem(16px);
    left: pxToRem(18px);
    width: pxToRem(91px);
    height: fit-content;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: var(--blackBase);
    z-index: 2;
}

.backgroundBlock {
    position: absolute;
    top: pxToRem(-28px);
    left: pxToRem(93px);
    width: pxToRem(70.75px);
    height: pxToRem(188.17px);
    background: linear-gradient(
        to top,
        rgba(196, 196, 196, 0.3),
        var(--whiteBase)
    );
    transform: rotate(208.18deg);
}

.navPic {
    position: absolute;
    bottom: pxToRem(17px);
    left: pxToRem(203px);
    width: pxToRem(51px);
    height: pxToRem(51px);
}

.navImg {
    width: pxToRem(51px);
    height: pxToRem(51px);
}
