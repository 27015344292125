@import '@/shared/styles/pxToRem.scss';
.item {
    position: absolute;
}

.container {
    left: pxToRem(-100px);
    transition: 0.4s ease;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
.isMounted {
    left: pxToRem(-65px);
    opacity: 1;
}
.items {
    position: absolute;
    top: 0;
    pointer-events: auto;
}
.image {
    width: pxToRem(1095px);
    width: pxToRem(1080px);
}
