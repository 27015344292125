.wrapper {
    gap: 12px;
    height: 60px;
    width: auto;
}
.item {
    min-width: 47px;
    border: none;
    border-bottom: 3px solid var(--whiteBase);
    background-color: transparent;
    color: var(--whiteBase);
    padding-bottom: 2px;
    transition: 0.1s ease border-bottom-color;
}
.item_filled {
    border-bottom-color: var(--yellow100);
}
.item_inactive {
}
.item_selected {
    outline: none;
    border-bottom-color: rgb(139, 139, 139);
}

.item.item_selected.item_filled {
    &:last-of-type {
        border-bottom-color: var(--yellow100);
    }
}
