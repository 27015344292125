@import 'src/shared/styles/pxToRem';
$text-font: 16px;
$text-line-height: 24px;
$text-div-width: 167px;
$hours-margin-top: 14px;
$hours-font: 36px;
$hours-line-height: 42px;

$scale: 1;

.timerWidget {
    font-family: 'Roboto';
    font-style: normal;
    color: var(--grey400);
}

.text {
    font-weight: 400;
    font-size: pxToRem($text-font, $scale);
    line-height: pxToRem($text-line-height, $scale);
    width: pxToRem($text-div-width, $scale);
}

.hours {
    margin-top: pxToRem($hours-margin-top, $scale);
    font-weight: 500;
    font-size: pxToRem($hours-font, $scale);
    line-height: pxToRem($hours-line-height, $scale);
}
