@import "src/shared/styles/pxToRem";

.container {
    width: pxToRem(16px);
    height: pxToRem(16px);
    box-sizing: border-box;
    border: pxToRem(1px) solid rgb(82, 82, 81);
    background: rgb(34, 34, 34);
    cursor: pointer;
}

.icon {
    width: pxToRem(16px);
    height: pxToRem(12px);
}

.wrapper {
    display: flex;
    color: rgb(148, 148, 148);
    font-size: pxToRem(14px);
    font-weight: 400;
    line-height: pxToRem(17px);
    gap: pxToRem(6px);
    align-items: center;
}

.input {
    display: none;
}