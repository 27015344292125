@import '@/shared/styles/pxToRem.scss';

.wrapper {
    width: fit-content;
    height: auto;
    border-radius: pxToRem(3px);
    background: linear-gradient(
        90deg,
        rgba(17, 17, 17, 0) 0.13%,
        rgba(17, 17, 17, 0.5) 33.9%,
        rgba(17, 17, 17, 0.48) 62.48%,
        rgba(17, 17, 17, 0) 99.89%
    );
    position: absolute;
    top: pxToRem(219px);
    right: pxToRem(20px);
    padding: pxToRem(6px) pxToRem(15px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: pxToRem(10px);
    .quest {
        width: fit-content;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: pxToRem(13px);
        .circleContainer {
            width: pxToRem(46px);
            height: pxToRem(46px);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .circle {
                width: pxToRem(24px);
                height: pxToRem(24px);
                border-radius: 50%;
                color: var(--whiteBase);
                font-family: Roboto;
                font-size: pxToRem(14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                justify-content: center;
                align-items: center;
                text-shadow: pxToRem(0px) pxToRem(0px) pxToRem(5px)
                    rgba(0, 0, 0, 0.5);
            }
            .arrowContainer {
                width: pxToRem(8px);
                height: pxToRem(46px);
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                .arrow {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    translate: -50% 0;
                    width: pxToRem(8px);
                    height: pxToRem(8px);
                    rotate: 84deg;
                }
            }
        }
        .info {
            width: fit-content;
            height: auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: pxToRem(2px);
            .title {
                color: #c8c7c7;
                font-family: 'Brutal Type';
                font-size: pxToRem(14px);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .stages {
                width: fit-content;
                height: auto;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                gap: pxToRem(1px);
                .stage {
                    color: #6f6f6f;
                    font-family: 'Brutal Type';
                    font-size: pxToRem(10px);
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    &Completed {
                        color: #c8c7c7;
                    }
                }
            }
        }
    }
}
